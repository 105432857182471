<template>
	<div>
		<p
			style="
				font: normal normal bold 14px/19px Nunito;
				letter-spacing: 0px;
				color: #525355;
			"
			class="mt-3"
		>
			<slot name="heading"> </slot>
		</p>
		<v-btn-toggle v-model="multiToggleLocal" mandatory color="#62b6dc">
			<v-btn value="0">
				<v-icon left> </v-icon>
				<span>0</span>

				<v-icon right> </v-icon>
			</v-btn>
			<v-btn value="1">
				<v-icon left> </v-icon>
				<span>1</span>

				<v-icon right> </v-icon>
			</v-btn>

			<v-btn value="2">
				<v-icon left> </v-icon>
				<span>2</span>

				<v-icon right> </v-icon>
			</v-btn>

			<v-btn value="3plus">
				<v-icon left> </v-icon>
				<span>3+</span>

				<v-icon right> </v-icon>
			</v-btn>
		</v-btn-toggle>
	</div>
</template>

<script>
export default {
	name: "J3MultiToggle",

	props: {
		question: {
			type: String,
			default: "",
		},
		value: {
			type: String,
			default: "",
		},
	},
	watch: {
		value: function (val, oldVal) {
			this.$emit("input", val);
		},
	},
	computed: {
		multiToggleLocal: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				this.$emit("input", value);
			},
		},
	},
};
</script>
